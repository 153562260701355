/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const NotoSans_100Thin = require('./NotoSans_100Thin.ttf');
export const NotoSans_100Thin_Italic = require('./NotoSans_100Thin_Italic.ttf');
export const NotoSans_200ExtraLight = require('./NotoSans_200ExtraLight.ttf');
export const NotoSans_200ExtraLight_Italic = require('./NotoSans_200ExtraLight_Italic.ttf');
export const NotoSans_300Light = require('./NotoSans_300Light.ttf');
export const NotoSans_300Light_Italic = require('./NotoSans_300Light_Italic.ttf');
export const NotoSans_400Regular = require('./NotoSans_400Regular.ttf');
export const NotoSans_400Regular_Italic = require('./NotoSans_400Regular_Italic.ttf');
export const NotoSans_500Medium = require('./NotoSans_500Medium.ttf');
export const NotoSans_500Medium_Italic = require('./NotoSans_500Medium_Italic.ttf');
export const NotoSans_600SemiBold = require('./NotoSans_600SemiBold.ttf');
export const NotoSans_600SemiBold_Italic = require('./NotoSans_600SemiBold_Italic.ttf');
export const NotoSans_700Bold = require('./NotoSans_700Bold.ttf');
export const NotoSans_700Bold_Italic = require('./NotoSans_700Bold_Italic.ttf');
export const NotoSans_800ExtraBold = require('./NotoSans_800ExtraBold.ttf');
export const NotoSans_800ExtraBold_Italic = require('./NotoSans_800ExtraBold_Italic.ttf');
export const NotoSans_900Black = require('./NotoSans_900Black.ttf');
export const NotoSans_900Black_Italic = require('./NotoSans_900Black_Italic.ttf');
